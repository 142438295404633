import type { InterceptorProps } from "./_model";
import { error, warn } from "./const";

export function attachResponseInterceptor(props: InterceptorProps) {
  props.instance.interceptors.response.use(
    async (res) => {
      // @ts-ignore
      res = props.options?.interceptors?.processResponds?.({ res }) ?? res;
      const config = res.config;
      const state = props.getConfigState(config);
      if (!state.auth.is_auth) {
        return Promise.resolve(res);
      }
      if (props.options.auth.mode === "http-only-cookie") {
        if (state.auth.endpoints.is_auth_endpoint) {
          props.revalidateAuthedCall?.({ res, state });
        }
      } else if (props.options.auth.mode === "localstorage") {
        if (state.auth.endpoints.is_auth_endpoint) {
          props.revalidateAuthedCall?.({ res, state });
        }
      }
      return Promise.resolve(res);
    },
    ///////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////
    async (err) => {
      // @ts-ignore
      err = props.options?.interceptors?.processRespondsErrors?.({ err }) ?? err;
      const config = err.config ?? {};
      const internalConfig = props.getInternalConfig();
      const state = props.getConfigState(config);
      if (!state.auth.is_auth) {
        return Promise.reject(err);
      }
      // in http only cookie, refreshing of token is handled on backend side, so we just return the error
      const token_response = props.getTokenResponse();
      // const jwt = props.getJWTDecoded();
      if (props.options.auth.mode === "http-only-cookie") {
        if (!state.auth.endpoints.is_auth_endpoint) {
          const one_time_session_allowed = !props.options?.auth?.disableOneTimeSessionToken;
          const activate_ots = !internalConfig.httpOnlyCookieTempSession && token_response && one_time_session_allowed;
          if (activate_ots) {
            warn("one time session only token activated upon a call to ", config?.url);
            config._retry = true;
            const token = props.options.auth?.events?.getToken({ res: token_response });
            config.header = { ...config.header, Authorization: `Bearer ${token}` };
            props.updateInternalConfig({ httpOnlyCookieTempSession: true });
            props.options.auth?.triggers?.onOneTimeSessionsActivated?.(err);
            return props.instance(config);
          }
        }
      } else if (props.options.auth.mode === "localstorage") {
        const token_endpoint = props.options.auth.endpoints.postToken;
        const refresh_endpoint = props.options.auth.endpoints.postTokenRevalidate.url;
        const unauthorizedStatusCode = props.options.auth?.defaults?.unauthorizedStatusCode;
        // console.log("check access token response expire :: ", unauthorizedStatusCode, " :: ", config._retry);
        if (config.url !== token_endpoint && err.response?.status === unauthorizedStatusCode && !config._retry) {
          // Access Token was expired
          const token_refresh = props.options.auth?.events?.getTokenRefresh?.({ res: token_response });
          // console.log("access token expired :: ", token_refresh);
          if (token_refresh === undefined || token_refresh === null) {
            error(
              "token refresh obtained through options.auth?.events?.getTokenRefresh is undefined or null,",
              " aborting refresh and logging out"
            );
          } else {
            try {
              config._retry = true;
              await props.instance.post(refresh_endpoint, token_refresh, config);
              // props.revalidateAuthedCall?.({ res, state });
              return props.instance(config);
            } catch (_error) {
              err = _error;
            }
          }
        }
        // const token = options.auth?.events?.getToken(token_response);
      }
      props.revalidateAuthedCall?.({ err, state });
      return Promise.reject(err);
    }
  );
}
